.inputDni, .btn-dni{
    height: 50px;
}


.error-msg{
    padding: 10px;
    background: #FA0F00;
    width: 100%;
    margin: 5px auto;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.content-dni{
    padding-top: 15px;
    padding-bottom: 15px;
}

@media (max-width: 700px) {
    .content-dni {
        --bs-gutter-x: 0px !important;
    }
    .inputDni{
        width: 90%;
        margin: 0 auto;
    }
}
