body {
    background: #f7fafc;
    font-family: Almarai, sans-serif;
}

h6{
    font-size: 22px;
}

.div-center {
    background: #fff;
    width: 75%;
    padding: 0;
    margin: 0 auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 8px 8px;
    position: relative;
    margin-bottom: 30px;
}

.special-width{
    width: 60% !important;
}

.div-table{
    border: 1px solid;
    text-align: center;
}

.first-col{
    visibility: hidden;
}

@media (max-width: 700px) {
    .div-center, .special-width{
        width: 90% !important;
        box-shadow: none;
        margin-bottom: 4rem;
    }
    .row{
        --bs-gutter-x: 0px !important;
    }
    .btn-blue-survey,
    .btn-grey-survey{
        width: 45% !important;
    }
}
.col-question{
    padding: 20px;
}

.col-question .select-question{
    margin-left: 25px;
    cursor: pointer;
}

.btn-blue-survey {
    background-color: #249CF3 !important;
    border-color: #249CF3 !important;
    border-radius: 8;
    width: 50%;
    /* margin-top: 100px; */
    margin: 40px auto;
}

.btn-grey-survey {
    background-color: #666666 !important;
    border-color: #666666 !important;
    border-radius: 8;
    width: 50%;
    /* margin-top: 100px; */
    margin: 40px auto;
}

.col-btns button:first-child{
    margin-right: 5px;
}
.col-btns button:last-child{
    margin-left: 5px;
}

.progress-bar-content{
    margin: 35px auto 20px auto;
}

.content-error-survey{
    margin: 0 auto;
    text-align: center;
}

.row-error{
    margin: 0 !important;
}

.content-error-survey p {
    font-size: 19px;
    font-weight: bold;
    border-bottom: 1px solid;
    color: #e45f5f;
}

.result-content{
    margin: 0 auto;
}

.result-text{
    position: relative;
}


.result-icon svg{
    width: 95%;
}

.select-question{
    margin-top: 25px;
}

.select-question input {
    width: 1.5em;
    height: 1.5em;
    margin-top: -1px;
    margin-right: 15px;
    border-color: #000000;
}

.col-btns{
    margin: 0 auto;
}

.col-btns p {
    margin: 0 !important;
}

.col-btns button:first-child{
    margin-right: 5px;
}
.col-btns button:last-child{
    margin-left: 5px;
}


.active-question{
    display: block !important;
}
div[class*='div-question-']{
    display: none;
}

.th-answers{
    text-align: center;
    font-size: 15px;
}
.matrix-movil{
    display: none;
}
@media (max-width: 700px) {
    
    .result-text::before{
        content: "• ";
        position: absolute;
        left: -24px;
        color: #04477A;
        font-size: 60px;
        top: -43px;
    }

    .result-text{
        margin-left: 23px
    }

    .matrix-web{
        display: none;
    }
    .matrix-movil{
        display: block;
    }
    .col-header{
        width: 90%;
        margin: 0 auto;
    }

}

.select-qty-matrix input{
    margin-top: -10px !important; 
}
/* matrix modal*/
.question-label{
    font-size: 1rem;
    margin: 0;
}

.matrix-movil .select-question {
    text-align: center !important;
    font-size: 11px !important;
    display: ruby !important;
    padding: 6px !important;
}
.form-select-movil input{
    float: none !important;
    margin: 0 !important;
}

.content-matrix-movil{
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    margin: 0 auto;
    width: 95%;
    padding: 10px;
}
.section-title{
    font-weight: bold;
}
.section-description{
    font-style: italic;
}
.col-header{
    border-bottom: 1px solid #d0d5da;
}
/* matrix modal*/

